import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Tent1 from "../../images/Tent1.jpeg";
import Tent2 from "../../images/Tent2.jpeg";
import Tent3 from "../../images/Tent3.jpeg";
import Tent4 from "../../images/Tent4.jpeg";
import Tent5 from "../../images/Tent5.jpeg";
import Tent6 from "../../images/Tent6.jpeg";

import { StyledSection } from "./StyledImageCarousal";

const TentList = [Tent1, Tent2, Tent3, Tent4, Tent5, Tent6];

const ImageCarousalForm = () => (
  <StyledSection>
    <Carousel
      showArrows={true}
      autoPlay={true}
      transitionTime={500}
      showIndicators={true}
      interval={2000}
      infiniteLoop={true}
      dynamicHeight={true}
    >
      {TentList.map((tent, key) => {
        return (
          <div key={key}>
            <img src={tent} alt="Tent Images" />
          </div>
        );
      })}
    </Carousel>
  </StyledSection>
);

export default ImageCarousalForm;
