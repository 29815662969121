import styled from "styled-components";
import { Link } from "react-router-dom";

import { colors } from "../../constants";

export const StyledButton = styled(Link)`
  display: inline-block;
  background-color: ${colors.purple};
  color: white;
  font-weight: 500;
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  transition: filter 0.25s ease-in-out;
  text-align: center;

  &:hover {
    filter: brightness(110%);
  }
`;
