import styled from "styled-components";

import Button from "../Button/Button";

export const StyledHeading = styled.h1`
  font-size: 2rem;

  @media (min-width: 48em) {
    font-size: 3rem;
  }
`;

export const StyledParagraph = styled.p`
  font-family: Cormorant Garamond;
  font-size: 1.3rem;
  font-weight: 580;
  text-align: center;
`;

export const StyledButton = styled(Button)`
  font-size: 1.1rem;
  padding: 1em 2em;
`;

export const StyledHomeLogo = styled.div`
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
`;

export const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
`;
