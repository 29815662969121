import { useState } from "react";
import { BurgerMenu } from "./BurgerMenu";
import { NavbarStyle, MobileView } from "./NavbarStyles";
import { Link } from "react-router-dom";

const Navbar = () => {
  // for open/close mobile menu
  const [burgerOpen, setBurgerOpen] = useState(false);
  const toggleBurger = () => {
    setBurgerOpen(!burgerOpen);
    // return burgerOpen;
  };

  // MUI tab component
  // const [value, setValue] = useState("home");

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  //   console.log({newValue})
  //   // switch (newValue) {
  //   //   case "home":
  //   //     <Redirect to={"/"} component={LandingPage} />
  //   //   //   <Redirect
  //   //   //   to={ }
  //   //   // />
  //   //     break;

  //   //   case "about":
  //   //     <Redirect to={"/about"} component={AboutPage} />
  //   //     break;

  //   //   default:
  //   //     break;
  //   // }
  // };

  return (
    <div className="navbar-wrapper">
      <NavbarStyle>
        <Link to="/" onClick={toggleBurger}>
          HOME
        </Link>
        <Link to="/contact" onClick={toggleBurger}>
          CONTACT
        </Link>
      </NavbarStyle>

      {/* <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        sx={{ width: 500 }}
        >
        <Tab value="home" label="HOME" children={<LandingPage />}/>
        <Tab value="about" label="ABOUT" children={<AboutPage />}/>
        <Tab value="contact" label="CONTACT US" children={<ContactPage />}/>
      </Tabs> */}

      <MobileView>
        <div className="burgermenu-toggle" onClick={toggleBurger}>
          <BurgerMenu />
        </div>
      </MobileView>

      <style jsx>
        {`
          @media (max-width: 550px) {
            .navbar-wrapper > div > a {
              display: ${burgerOpen ? "inline" : "none"};
              // color: red;
            }
          }
        `}
      </style>
    </div>
  );
};

export { Navbar };
