import {
  Border,
  FooterA,
  FooterP,
  HeaderFooterP,
  StyledFooterColumn,
  StyledFooterRow,
  StyledFooterSection,
} from "./StyledFooter";
import InstaIcon from "../../images/Instagram.svg";

const Footer = () => {
  return (
    <StyledFooterSection>
      <StyledFooterColumn>
        <HeaderFooterP>KidsCampFiesta</HeaderFooterP>
        <FooterP>
          Camp anytime........Get ready for a night of friendship and fun as you
          stay up late and enjoy a sleepover together
        </FooterP>
      </StyledFooterColumn>
      <StyledFooterRow margin>
        <StyledFooterRow>
          <StyledFooterRow linkContainer>
            <StyledFooterColumn>
              <HeaderFooterP smallHeader>Information</HeaderFooterP>
              <FooterA
                href={
                  "mailto:dallasdreamers4@gmail.com?subject=Subject&body=Body%20goes%20here"
                }
              >
                Email: orders@kidscampfiesta.com
              </FooterA>
              <FooterA href={"tel:469-305-2861"}>
                Phone: +1(469) 305-2861
              </FooterA>
              <FooterA href={"https://www.instagram.com/kidscampfiesta/"}>
                <img
                  src={InstaIcon}
                  style={{ width: "40px" }}
                  alt="Home Logo"
                />
              </FooterA>
            </StyledFooterColumn>

            {/* ::Todo Add FAQ's in future */}
            {/* <StyledFooterColumn>
              <HeaderFooterP smallHeader>About</HeaderFooterP>
              <FooterA href="#">News and press</FooterA>
              <FooterA href="#">FAQ</FooterA>
            </StyledFooterColumn> */}
          </StyledFooterRow>
        </StyledFooterRow>
      </StyledFooterRow>
      <Border />
      <StyledFooterRow bottomContainer>
        <FooterP bottom>
          Copyright 2023 KidsCampFiesta, All rights reserved.
        </FooterP>

        {/* ::ToDo Add them in future */}
        {/* <StyledFooterRow bottomDiv>
          <FooterP bottom>Terms of Service</FooterP>
          <FooterP bottom>Privacy Policy</FooterP>
        </StyledFooterRow> */}
      </StyledFooterRow>
    </StyledFooterSection>
  );
};

export default Footer;
