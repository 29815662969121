import React from "react";
import PageHeader from "../PageHeader/PageHeader";
import emailjs from "@emailjs/browser";
import { TextField, Select, MenuItem } from "@mui/material";

import {
  StyledForm,
  StyledLabel,
  StyledInput,
  StyledTextArea,
  StyledSubmitButton,
  StyledCalendar,
} from "./StyledContact";
import { useRef } from "react";
import moment from "moment";

const ContactForm = () => {
  const form = useRef();

  const [value, setValue] = React.useState();
  const [theme, setTheme] = React.useState();

  const handleCalendarChange = (newValue) => {
    setValue(newValue);
  };

  const handleThemeChange = (newValue) => {
    setTheme(newValue);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // alert("Form may or may not have been submitted :)");

    const templateParams = {
      user_name: e.target.user_name.value,
      user_email: e.target.user_email.value,
      user_message: e.target.user_message.value,
      user_requestedDate: value,
      user_numberTents: e.target.user_numberTents.value,
      user_selectedTheme: e.target.user_selectedTheme.value,
    };

    emailjs
      .send(
        "service_v7fporc",
        "template_0ptkclg",
        templateParams,
        "KHOVaNynuuJEJsTYG"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert(
            "Thank you for contacting us! We will look into your message and get back to you shortly."
          );
        },
        (error) => {
          console.log(error.text);
          alert("something went wrong");
        }
      );

    e.target.reset();
  };

  return (
    <>
      <PageHeader
        title="Get a Custom Quote"
        description={
          "We can assist in creating your ideal camping experience with a 30-day advance notice."
        }
      />
      <section>
        <StyledForm ref={form} onSubmit={handleFormSubmit}>
          <StyledLabel>
            Name:
            <StyledInput type="text " name="user_name" required />
          </StyledLabel>

          <StyledLabel>
            E-mail:
            <StyledInput type="email" name="user_email" required />
          </StyledLabel>

          <StyledLabel>
            Address:
            <StyledInput type="text" name="user_address" />
          </StyledLabel>

          <StyledLabel>
            Phone:
            <StyledInput type="number" name="user_phoneNumber" required />
          </StyledLabel>

          <StyledLabel>
            Number of Tents:
            <StyledInput type="number" name="user_numberTents" required />
          </StyledLabel>

          <StyledLabel>
            Select Theme:
            <Select
              id="user_selectedTheme"
              name="user_selectedTheme"
              value={theme}
              onChange={handleThemeChange}
              style={{ width: "100%" }}
            >
              <MenuItem value={"Jungle Theme"}>Jungle Theme</MenuItem>
              <MenuItem value={"Game Night Theme"}>Game Night Theme</MenuItem>
              <MenuItem value={"Rainbow Theme"}>Rainbow Theme</MenuItem>
              <MenuItem value={"Sea Theme"}>Sea Theme</MenuItem>
              <MenuItem value={"Floral Theme"}>Floral Theme</MenuItem>
              <MenuItem value={"Dinosaur Theme"}>Dinosaur Theme</MenuItem>
              <MenuItem value={"Custom Theme"}>Custom Theme</MenuItem>
            </Select>
          </StyledLabel>

          <StyledLabel>
            Rental Date:
            <StyledCalendar
              name="user_requestedDate"
              value={value}
              inputFormat={"MM/DD/YYYY"}
              onChange={handleCalendarChange}
              minDate={moment("2017-01-01")}
              disablePast
              renderInput={(params) => <TextField {...params} />}
            />
          </StyledLabel>

          <StyledLabel>
            Message:
            <StyledTextArea name="user_message" required />
          </StyledLabel>

          <StyledSubmitButton type="submit" value="Send" />
        </StyledForm>
      </section>
    </>
  );
};

export default ContactForm;
