import {
  Container,
  Columns,
  FirstColumn,
  SecondColumn,
  ThirdColumn,
  TextStyle,
  BorderLine,
  HeaderTextStyle,
} from "./StyledTentList";
import ContactForm from "./ContactForm";

const TentListForm = () => (
  <Container>
    <Columns>
      <FirstColumn>
        <HeaderTextStyle>{"Theme Name"}</HeaderTextStyle>
      </FirstColumn>
      <SecondColumn>
        <HeaderTextStyle> {"Service Fee"} </HeaderTextStyle>
      </SecondColumn>
      <ThirdColumn>
        <HeaderTextStyle> {"Tent Cost"} </HeaderTextStyle>
      </ThirdColumn>
    </Columns>
    <BorderLine />
    <Columns>
      <FirstColumn>
        <TextStyle>{"Jungle"}</TextStyle>
      </FirstColumn>
      <SecondColumn>
        <TextStyle>125$</TextStyle>
      </SecondColumn>
      <ThirdColumn>
        <TextStyle>80$</TextStyle>
      </ThirdColumn>
    </Columns>
    <BorderLine />
    <Columns>
      <FirstColumn>
        <TextStyle>{"Game Night"}</TextStyle>
      </FirstColumn>
      <SecondColumn>
        <TextStyle>125$</TextStyle>
      </SecondColumn>
      <ThirdColumn>
        <TextStyle>80$</TextStyle>
      </ThirdColumn>
    </Columns>
    <BorderLine />
    <Columns>
      <FirstColumn>
        <TextStyle>{"Rainbow"}</TextStyle>
      </FirstColumn>
      <SecondColumn>
        <TextStyle>125$</TextStyle>
      </SecondColumn>
      <ThirdColumn>
        <TextStyle>80$</TextStyle>
      </ThirdColumn>
    </Columns>
    <BorderLine />
    <Columns>
      <FirstColumn>
        <TextStyle>{"Sea"}</TextStyle>
      </FirstColumn>
      <SecondColumn>
        <TextStyle>125$</TextStyle>
      </SecondColumn>
      <ThirdColumn>
        <TextStyle>80$</TextStyle>
      </ThirdColumn>
    </Columns>
    <BorderLine />
    <Columns>
      <FirstColumn>
        <TextStyle>{"Floral"}</TextStyle>
      </FirstColumn>
      <SecondColumn>
        <TextStyle>125$</TextStyle>
      </SecondColumn>
      <ThirdColumn>
        <TextStyle>80$</TextStyle>
      </ThirdColumn>
    </Columns>
    <BorderLine />
    <Columns>
      <FirstColumn>
        <TextStyle>{"Dinosaur"}</TextStyle>
      </FirstColumn>
      <SecondColumn>
        <TextStyle>125$</TextStyle>
      </SecondColumn>
      <ThirdColumn>
        <TextStyle>80$</TextStyle>
      </ThirdColumn>
    </Columns>
    <BorderLine />

    {/* Contact Form */}
    <ContactForm />
  </Container>
);

export default TentListForm;
