import styled from "styled-components";

export const StyledSection = styled.section`
  background-color: #5b4cad;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding-block: 4em;
  color: #ffffff;
`;
