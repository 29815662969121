import {
  // StyledHeading,
  StyledParagraph,
  StyledButton,
  StyledHomeLogo,
  StyledBody,
} from "./StyledHero";
import HomeLogo from "../../images/HomeLogo.png";

const HeroSection = () => (
  <>
    <StyledHomeLogo>
      <img src={HomeLogo} alt="Home Logo" />
    </StyledHomeLogo>
    <StyledBody>
      {/* <StyledHeading style={{ fontFamily: "Cormorant Upright" }}>
        KidsCampFiesta
      </StyledHeading> */}
      <StyledParagraph>
        "Experience the ultimate sleepover adventure with KidsCampFiesta. Our
        indoor tent rentals come in a variety of creative themes. We can also
        create a custom theme. We are based in Frisco, TX and serving the
        surrounding areas within a 30 mile radius."
      </StyledParagraph>
      <StyledButton to="/contact">Contact us</StyledButton>
    </StyledBody>
  </>
);

export default HeroSection;
