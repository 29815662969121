import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import GlobalStyles from "./GlobalStyles";

import Layout from "./components/Layout/Layout";
import { Header } from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"; //To be removed
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import NotFoundPage from "./pages/NotFound/NotFound";
import LandingPage from "./pages/Landing/Landing";
import ContactPage from "./pages/Contact/Contact";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <GlobalStyles />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Header />
        <Layout>
          <Routes>
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
          <Footer />
        </Layout>
      </LocalizationProvider>
    </>
  );
}

export default App;
