import styled from "styled-components";
import { Typography } from "@mui/material";

export const Container = styled.main`
  padding: 20px;
  margin: 0 auto;
  max-width: 700px;
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FirstColumn = styled.div`
  width: 50%;
  padding: 20px;
`;

export const SecondColumn = styled.div`
  width: 25%;
  padding: 20px;
`;

export const ThirdColumn = styled.div`
  width: 25%;
  padding: 20px;
`;

export const BorderLine = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  height: 2px;
  background-color: #5b4cad;
  max-width: 700px;
`;

export const TextStyle = styled(Typography).attrs({
  fontFamily: "Cormorant Garamond",
  fontSize: "1.3rem",
})``;

export const HeaderTextStyle = styled(Typography).attrs({
  fontFamily: "Cormorant Garamond",
  fontSize: "1.3rem",
  fontWeight: "600",
})``;
