import HeroSection from "../../components/Hero/Hero";
import ImageCarousalForm from "../../components/ImageCarousal/ImageCarousalForm";
import TentListForm from "../../components/TentList/TentListForm";

const LandingPage = () => (
  <>
    <HeroSection />
    <ImageCarousalForm />
    <TentListForm />
  </>
);

export default LandingPage;
